//import './App.css';
import React, {useEffect, useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import KeyValuePairEntry from "./Components/KeyValuePairEntry";
import axios from "axios";
import prettyBytes from "pretty-bytes";
import {EditorView, keymap} from "@codemirror/view";
import {basicSetup, EditorState} from "@codemirror/basic-setup";
import {defaultTabBinding} from "@codemirror/commands";
import {json} from "@codemirror/lang-json";

function App() {

    const [method, setMethod] = useState("GET")
    const [url, setUrl] = useState("")
    const [queryParams, setQueryParams] = useState([])
    const [requestHeaders, setRequestHeaders] = useState([])
    const [idCounterQueryParams, setIdCounterQueryParams] = useState(0)
    const [idCounterRequestHeaders, setIdCounterRequestHeaders] = useState(0)

    const [responseStatus, setResponseStatus] = useState()
    const [responseTime, setResponseTime] = useState()
    const [responseSize, setResponseSize] = useState()
    const [responseHeaders, setResponseHeaders] = useState([])

    const [requestEditor, setRequestEditor] = useState(new EditorView())
    const [responseEditor, setResponseEditor] = useState(new EditorView())

    const updateEndTime = (response) => {
        response.customData = response.customData || {}
        response.customData.time = new Date().getTime() - response.config.customData.startTime
        return response
    }

    const updateResponseDetails = (response) => {
        setResponseStatus(response.status)
        setResponseTime(response.customData.time)
    }


    const extensions = [
        basicSetup,
        keymap.of([defaultTabBinding]),
        json(),
        EditorState.tabSize.of(2)
    ]

    useEffect(() =>{
        setRequestEditor(new EditorView({
            state: EditorState.create({
                doc: "{\n\t\n}",
                extensions: extensions
            }),
            parent: document.querySelector('[data-json-request-body]')
        }))
        setResponseEditor(new EditorView({
            state: EditorState.create({
                doc: "",
                extensions: [...extensions, EditorView.editable.of(false)]
            }),
            parent: document.querySelector('[data-json-response-body]')
        }))
    }, [])


    const updateResponseEditor = (value) => {
        responseEditor.dispatch({
            changes: {
                from: 0,
                to: responseEditor.state.doc.length,
                insert: JSON.stringify(value, null, 2)
            }
        })
    }


    const handleFormSubmit = (e) =>{
        e.preventDefault()
        let data
        try{
            data = JSON.parse(requestEditor.state.doc.toString() || null)

        } catch (e){
            alert("JSON data is malformed")
            return
        }
        let params = queryParams.reduce((data, queryParam) => {
            if(queryParam.key == "") return data
            return {...data, [queryParam.key]: queryParam.value}
        }, {})
        let headers = requestHeaders.reduce((data, requestHeader) => {
            if(requestHeader.key == "") return data
            return {...data, [requestHeader.key]: requestHeader.value}
        }, {})

        axios.interceptors.request.use(request => {
            request.customData = request.customData || {}
            request.customData.startTime = new Date().getTime()
            return request
        })

        axios.interceptors.response.use((response) =>{
            updateEndTime(response)
            return response
        }, (e) =>{
            return Promise.reject(updateEndTime(e.response))
        })

        axios({
            url: url,
            method: method,
            params: params,
            headers: headers,
            data: data
        })
            .catch(e => e)
            .then(response => {
                updateResponseDetails(response)

                let tempHeaders = []
                Object.entries(response.headers).forEach(([key, value]) =>{
                    tempHeaders.push({key: key, value: value})
                })
                setResponseHeaders(tempHeaders)
                setResponseSize(prettyBytes(JSON.stringify(response.data).length + JSON.stringify(response.headers).length))
                updateResponseEditor(response.data)
        })
    }





    const handleAddQueryParam = () =>{
        let tempId = idCounterQueryParams + 1
        queryParams.push({id: tempId, key: '', value: ''})
        setIdCounterQueryParams(idCounterQueryParams + 1)
    }

    const handleSetQueryParamKey = (e) =>{
        let index = queryParams.findIndex((obj) => obj.id == e.id)
        let temp = queryParams
        temp[index].key = e.key
        setQueryParams(temp)
    }

    const handleSetQueryParamValue = (e) =>{
        let index = queryParams.findIndex((obj) => obj.id == e.id)
        let temp = queryParams
        temp[index].value = e.value
        setQueryParams(temp)
    }

    const handleRemoveQueryParam = (e) => {
        setQueryParams(queryParams.filter((obj) => {return obj.id !== e.id}))
    }

    const handleAddRequestHeader = () =>{
        let tempId = idCounterRequestHeaders + 1
        requestHeaders.push({id: tempId, key: '', value: ''})
        setIdCounterRequestHeaders(idCounterRequestHeaders + 1)
    }

    const handleSetRequestHeaderKey = (e) =>{
        let index = requestHeaders.findIndex((obj) => obj.id == e.id)
        let temp = requestHeaders
        temp[index].key = e.key
        setRequestHeaders(temp)
    }

    const handleSetRequestHeaderValue = (e) =>{
        let index = requestHeaders.findIndex((obj) => obj.id == e.id)
        let temp = requestHeaders
        temp[index].value = e.value
        setRequestHeaders(temp)
    }

    const handleRemoveRequestHeader = (e) => {
        setRequestHeaders(requestHeaders.filter((obj) => {return obj.id !== e.id}))
    }


    const queryParamsList = queryParams.map((queryParam) => {
        return <KeyValuePairEntry key={queryParam.id}
                                  id={queryParam.id}
                                  keyParam={queryParam.key}
                                  valueParam={queryParam.value}
                                  handleRemove={handleRemoveQueryParam}
                                  handleSetKeyParam={handleSetQueryParamKey}
                                  handleSetValueParam={handleSetQueryParamValue}
        />
    })

    const requestHeadersList = requestHeaders.map((requestHeader) => {
        return <KeyValuePairEntry key={requestHeader.id}
                                  id={requestHeader.id}
                                  keyParam={requestHeader.key}
                                  valueParam={requestHeader.value}
                                  handleRemove={handleRemoveRequestHeader}
                                  handleSetKeyParam={handleSetRequestHeaderKey}
                                  handleSetValueParam={handleSetRequestHeaderValue}
        />
    })

    const responseHeadersList = responseHeaders.map((responseHeader) => {
        return(<div key={responseHeader.key}>
            <div>{responseHeader.key}</div>
            <div>{responseHeader.value}</div>
            </div>)
    })

  return (
    <div className="App">
      <div className="p-4">
          <form onSubmit={handleFormSubmit}>
              <div className="input-group mb-4">
                  <select className="form-select flex-grow-0 w-auto" value={method} onChange={(arg) => setMethod(arg.target.value)}>
                      <option value="GET">GET</option>
                      <option value="POST">POST</option>
                      <option value="PUT">PUT</option>
                      <option value="DELETE">DELETE</option>
                  </select>
                  <input required className="form-control" type="url" placeholder="https://example.com" value={url} onChange={(arg) => setUrl(arg.target.value)}/>
                  <button type="submit" className="btn btn-primary">Send</button>
              </div>
              <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="query-params-tab" data-bs-toggle="tab" data-bs-target="#query-params" type="button" role="tab" aria-controls="query-params" aria-selected="true">Query Params</button>
                  </li>
                  <li className="nav-item" role="presentation">
                      <button className="nav-link" id="request-headers-tab" data-bs-toggle="tab" data-bs-target="#request-headers" type="button" role="tab" aria-controls="request-headers" aria-selected="false">Headers</button>
                  </li>
                  <li className="nav-item" role="presentation">
                      <button className="nav-link" id="json-tab" data-bs-toggle="tab" data-bs-target="#json" type="button" role="tab" aria-controls="json" aria-selected="false">JSON</button>
                  </li>
              </ul>
              <div className="tab-content p-3 border-top-0 border">
                  <div className="tab-pane fade show active" id="query-params" role="tabpanel" aria-labelledby="query-params-tab">
                      {queryParamsList}
                      <div data-query-params></div>
                      <button data-add-query-param-btn className="mt-2 btn btn-outline-success" type="button" onClick={handleAddQueryParam}>Add</button>
                  </div>
                  <div className="tab-pane fade" id="request-headers" role="tabpanel" aria-labelledby="request-headers-tab">
                      {requestHeadersList}
                      <div data-request-headers></div>
                      <button data-add-request-header-btn className="mt-2 btn btn-outline-success" type="button" onClick={handleAddRequestHeader}>Add</button>
                  </div>
                  <div className="tab-pane fade" id="json" role="tabpanel" aria-labelledby="json-tab">
                      <div data-json-request-body className="overflow-auto" style={{maxHeight: "200px"}}>

                      </div>
                  </div>
              </div>
          </form>
          <div className="mt-5">
              <h3>Response</h3>
              <div className="d-flex my-2">
                  <div className="me-3">
                      Status: <span>{responseStatus}</span>
                  </div>
                  <div className="me-3">
                      Time: <span>{responseTime}</span> ms
                  </div>
                  <div className="me-3">
                      Size: <span>{responseSize}</span>
                  </div>
              </div>
              <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="response-body-tab" data-bs-toggle="tab" data-bs-target="#response-body" type="button" role="tab" aria-controls="response-body" aria-selected="true">Body</button>
                  </li>
                  <li className="nav-item" role="presentation">
                      <button className="nav-link" id="response-headers-tab" data-bs-toggle="tab" data-bs-target="#response-headers" type="button" role="tab" aria-controls="response-headers" aria-selected="false">Headers</button>
                  </li>
              </ul>
              <div className="tab-content p-3 border-top-0 border">
                  <div className="tab-pane fade show active" id="response-body" role="tabpanel" aria-labelledby="response-body-tab">
                      <div data-json-response-body className="overflow-auto" style={{maxHeight: "200px"}}>

                      </div>
                  </div>
                  <div className="tab-pane fade" id="response-headers" role="tabpanel" aria-labelledby="response-headers-tab">
                      <div style={{display: "grid", gridTemplateColumns: "auto 1fr", gap: ".5rem 2rem"}}>
                          {responseHeadersList}
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
  );
}

export default App;
