import React, {useState} from "react";


function KeyValuePairEntry(props){
    const [id, setId] = useState(props.id ? props.id : 999)
    const [keyParam,setKeyParam] = useState(props.keyParam ? props.keyParam : "")
    const [valueParam, setValueParam] = useState(props.valueParam ? props.valueParam : "")
    const handleRemove = () => {
        props.handleRemove({id: id})
    }

    const handleSetKey = (arg) => {
        setKeyParam(arg)
        props.handleSetKeyParam({id: id, key: arg})
    }

    const handleSetValue = (arg) => {
        setValueParam(arg)
        props.handleSetValueParam({id: id, value: arg})
    }

    return (
        <div className="input-group my-2">
            <input type="text" className="form-control" placeholder="Key" value={keyParam} onChange={(arg) => handleSetKey(arg.target.value)}/>
            <input type="text" className="form-control" placeholder="Value" value={valueParam} onChange={(arg) => handleSetValue(arg.target.value)}/>
            <button type="button" className="btn btn-outline-danger" onClick={handleRemove}>Remove</button>
        </div>
    )
}

export default KeyValuePairEntry